<template>
    <b-container fluid >
        <b-card title-header="title"  v-show="insightList.length" class="custom-modal-card-2" >
          
         <template #header>
      <h4 class="m-0 custom-font text-blue"><b>Insight Service Tests</b></h4>
    </template>
            <b-table
            thead-tr-class="d-none"
            style="max-height: 60vh;overflow-y: scroll;"
            :fields="fields"
            :items="insightList"
            striped
            responsive
            class="m-0"
            >

 <template #cell(_id)="data">
        <b class="text-blue"  style="cursor: pointer;" @click="openInsightTrx(data.value)">{{ data.value }}</b>
      </template>
      <template #cell(netType)="data">
       {{ networks[data.value] }}
      </template>
       <template #cell(createdAt)="data">
        {{ data.value.split("T")[0]}}
      </template>
            </b-table>
        </b-card>
    </b-container>
</template>
<script>
import { watch } from 'vue'
import InsightService from '../../../services/InsightService'
import NetworkTypeService from '../../../services/NetworkTypeService'

export default
{
name:"insighList",
props:{
    sid: {
        type: String,
    },
    sname: {
        type: String,
    },
    spname: {
        type: String,   
    },
    insight_id: {
        type: String,
    }
},
data(){
    return{
        fields: [
            {key: "_id", label: "ID"},
            {key: "netType", label: "Net|Op"},
            {key: "createdAt", label: "Date"},
        ],
        defaultPayload: {},
        insightList : [],
        networks: []
    }
},
mounted(){
   
    let country = localStorage.getItem('country')
    if(country){
        this.defaultPayload.country = country
    }
    if(this.sid) {
        this.defaultPayload.sid = this.sid
    }
    this.init()

},
methods:{

    init(){
        this.getList()
        this.getNetworks()
    },
    async getNetworks(){
        let country = localStorage.getItem('country')
        let response = await NetworkTypeService.getAllNetworkType(country)
        if(response.result){
            this.networks = response.data
        }
    },
    openInsightTrx(item) {
     
      const url = this.$router.resolve({
        name: 'InsightDetailedPage',
        query: {
          insight_id: item,
        }
      }).href;
    
      window.open(url, '_blank');
      return;
    },
    async getList(){
        let payload = this.defaultPayload
        let response = await InsightService.getApprovedInsightList(payload)
        // console.log('insight List',response)
        if(response.result) {
            this.insightList = response.data
        }
    }
},
watch: {
    sid: function (val) {
        this.defaultPayload.sid = val
        this.getList()
    },  
}
}
</script>