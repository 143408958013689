import api from './api'
import globalLit from './globalLit'
export default { 
  add(data) {
    return api.execute(`post`,`/moderator/trx`, data)
  },
  update(data) {
    return api.execute(`put`,`/moderator/insight`, data)
  },
  get(payload) {
    return api.execute('get', `/moderator/trx`+payload)
  },
  getInsightList(queryParams) {

    let queryString = ''
    for (let key in queryParams) {
      if (queryParams[key]) {
        queryString += `&${key}=${queryParams[key]}`
      }
    }
    console.log("query",queryString)
    return api.execute(`get`,`/moderator/insight/?${queryString}`)
    
  },

  saveStep(data) {
    return api.execute(`post`,`/moderator/insight/step`, data)
  },
  updateStep(data) {
    return api.execute(`put`,`/moderator/insight/step`, data)
  },
  deleteStep(data) {
    return api.execute(`delete`,`/moderator/insight/step`, data)
  },
  validateCounter(data) {
    let queryParams = ''
    for (let key in data) {
      if (data[key]) {
        queryParams += `&${key}=${data[key]}`
      }
    }
    return api.execute(`get`,`/moderator/insight/validate/count?${queryParams}`)
   
  },

  uploadVideo(data) {
    return api.execute('post', '/upload/video', data)
  },
  
  removeStepImage(data) {
    return api.execute(`delete`,`/moderator/insight/step/image`, data)
  },

  getFilteredTrx(payload){
    let path = `?page=${payload.page || ''}&limit=${payload.limit || ''}&sortby=${payload.sort || ''}&sortdir=${payload.order || ''}&country=${payload.country || '' }&nettype=${payload.nettype || ''}`
    if(payload.unmappedservice) {
      path += `&sid=${payload.unmappedservice}`
    }
    return api.execute('get', `/moderator/trx`+path)
  },
  remove(data) {
    return api.execute(`delete`,`/moderator/insight`, data)
  },

  

  insightTrxDetail(data) {
    return api.execute(`post`,`/insight/trx`, data)
  },
  insightLogDetail(id) {
    return api.execute(`get`,`/insight/logs/${id}`)
  },
  getInsightsSteps(id) {
    return api.execute(`get`,`/insight/steps/${id}`)
  },
  getApprovedInsightList(data) {

    let payload = globalLit.checkCountry(data)
    return api.execute(`post`,`/insight/approved/`, payload)
    
  },

  trxDetail(data) {
    return api.execute(`post`,`/transcation`, data)
  },
  getLandings(id) {
    return api.execute(`get`,`/image/landing/all/${id}`)
  },
  addCommnet(data) {
    return api.execute(`post`,`/comment`, data)
  },
  getComment(id) {
    return api.execute(`get`,`/comment?trxid=${id}`)
  },
  deleteComment(cid){
    return api.execute(`delete`,`/comment`, cid)
  },
  editComment(data){
    return api.execute(`put`,`/comment`, data)
  },
  csvUpload(data){
    return api.execute(`post`,`/moderator/trx/upload`,data)
  },
  getCounter() {
    return api.execute(`get`,`/moderator/trx/counter`)
  }
}